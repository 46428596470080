import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

const Opportunity = (props) => {

  return (
    <section className="opportunity gap-62 blog-started">
      <div className="container">


        <div className="img">
          <LazyLoadImage src="/assets/image/get-started.png" alt=""/>
        </div>

        <div className="col-lg-8 ml-auto">
          <div className="content">
            <div className="head">
              <h3>Ready to get started?</h3>
            </div>

            <OpportunityLinks/>
          </div>
        </div>

      </div>
    </section>
  )
}

const OpportunityLinks = () => {
  let dispatch = useDispatch()
  const goToLogin = () => {
    dispatch(push('/login'))
  }
  return (
    <React.Fragment>
      <ul>
        <li>
          <button type="button" className="btn" onClick={goToLogin}>student <LazyLoadImage
            src="/assets/image/right-arrow.svg"
            alt=""/>
          </button>
        </li>
        <li>
          <button type="button" className="btn" onClick={goToLogin}>agent <LazyLoadImage
            src="/assets/image/right-arrow.svg"
            alt=""/></button>
        </li>
        <li>
          <button type="button" className="btn" onClick={goToLogin}>school <LazyLoadImage
            src="/assets/image/right-arrow.svg"
            alt=""/></button>
        </li>
      </ul>
    </React.Fragment>
  )
}
export { Opportunity, OpportunityLinks }
