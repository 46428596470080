import React, { useState } from 'react'
import { Button, Card, Drawer, Form, Icon, Input, notification, Tooltip } from 'antd'
import { RowTable } from '../../../components/_utils/RowTable'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { InputBox } from '../../../components/_utils/appUtils'
import { RequestLoaApproved } from '../actions/chooseStudent'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import lodash from 'lodash'

const DocumentDrawer = (props) => {
  let dispatch = useDispatch()
  let {visible, onClose, studentData} = props
  let {document, applications} = studentData;

  const documentSubName = (name) => {
    let exp = name.split('.').pop()
    let nameVal = name.split('.')[0]
    if (nameVal.length > 10) {
      nameVal = `${nameVal.substring(0, 10)}[...].${exp}`
      return nameVal
    } else {
      return name
    }
  }
  const getDocumentObj = (documents, name) => {
    let findObj = lodash.filter(documents, (item) => {
      return item.documentType == name
    })
    lodash.each(findObj, (item) => {
      if (item && item.date) {
        item.stringDate = moment(item.date).format('DD MMMM, YYYY hh:mm a')
      }
      item.oldDoc = true
      item.title = `${item.name}, Uploaded : ${item.stringDate}`
    })
    findObj = lodash.sortBy(findObj, (item) => {
      return new Date(item.date)
    })
    if (findObj && findObj.length) {
      findObj[findObj.length - 1].oldDoc = false
    }
    return findObj
  }

  return (
    <Drawer visible={visible}
            title={'Student Documents'}
            width={600}
            closable={true}
            maskClosable={true}
            onClose={onClose}>
      <Card>
        {studentData && studentData.applications && studentData.applications.id ?
            <div className={'tableBox striped'}>
              <RowTable title={'Name (ID)'} value={<span>{studentData.name} ({studentData.studentId})</span>}/>
              <RowTable title={'Application ID'} value={<span>{studentData.applications.id}</span>}/>
              <RowTable title={'Application'} value={<span>{studentData.applications.courseName}</span>}/>
            </div> : null}
        <br/>

        {document && document.length ? <div>
          {document.map((item, key) => {
            return (
                <div className='box' key={key} style={{borderBottom: '1px solid black', paddingBottom: 10}}>
                  <h6 style={{marginTop: 20}}>
                    {item.documentType}
                  </h6>
                  <Tooltip placement='topRight' title={item.title} key={key}>
                    <a href={item.url} download={item.url}>
                    <span style={{
                      backgroundColor: '#ddd',
                      borderRadius: 5,
                      padding: 5
                    }}>  {documentSubName(item.name)}</span>
                    </a>
                  </Tooltip>
                </div>
            )
          })}
        </div> : null}
        {applications && applications.loaDocument && applications.loaDocument.length ? <div>
          {applications.loaDocument.map((item, key) => {
            return (
                <div className='box' key={key} style={{borderBottom: '1px solid black', paddingBottom: 10}}>
                  <h6 style={{marginTop: 20}}>
                    {item.status}
                  </h6>
                  <Tooltip placement='topRight' title={item.title} key={key}>
                    <a href={item.url} download={item.url}>
                    <span style={{
                      backgroundColor: '#ddd',
                      borderRadius: 5,
                      padding: 5
                    }}>  {documentSubName(item.name)}</span>
                  </a>
                </Tooltip>
              </div>
            )
          })}
        </div> : null}


        {applications && applications.signedVisaDraft && applications.signedVisaDraft.path ?
            <div className='box' style={{borderBottom: '1px solid black', paddingBottom: 10}}>
              <h6 style={{marginTop: 20}}>
                Signed Visa Draft
              </h6>
              <Tooltip placement='topRight' title={' Signed Visa Draft'}>
                <a href={applications.signedVisaDraft.path} download={applications.signedVisaDraft.path}>
                    <span style={{
                      backgroundColor: '#ddd',
                      borderRadius: 5,
                      padding: 5
                    }}>  {documentSubName(applications.signedVisaDraft.fileName)}</span>
                </a>
              </Tooltip>
            </div> : null
        }

      </Card>
    </Drawer>
  )
}
export default DocumentDrawer
