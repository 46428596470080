import { apiUrl } from '../../../settings'

export const evaluateCountryUrl = () => {
  return apiUrl + '/evaluate/country'
}
export const evaluateStateUrl = () => {
  return apiUrl + '/evaluate/states'
}
export const evaluateUniversityUrl = () => {
  return apiUrl + '/evaluate/university'
}
export const evaluateIntakesUrl = () => {
  return apiUrl + '/evaluate/intakes'
}
export const evaluateCourseLevelUrl = () => {
  return apiUrl + '/evaluate/courseLevel'
}
export const evaluateFeaturedUniversityUrl = () => {
  return apiUrl + '/evaluate/featuredUniversity'
}
export const evaluateFeaturedCourseUrl = () => {
  return apiUrl + '/evaluate/featuredCourse'
}
export const evaluateOtherUniversityUrl = () => {
  return apiUrl + '/evaluate/otherUniversity'
}
export const evaluateSearchCourseUrl = () => {
  return apiUrl + '/evaluate/searchCourse'
}
export const evaluateLoadMoreCourseUrl = () => {
  return apiUrl + '/evaluate/loadMoreCourse'
}
export const evaluateEducationUrl = () => {
  return apiUrl + '/evaluate/education'
}
export const evaluateEnglishRequirementUrl = () => {
  return apiUrl + '/evaluate/englishRequirement'
}
export const evaluateExamPercentageMaxUrl = () => {
  return apiUrl + '/evaluate/examPercentageMax'
}
export const evaluateEnglishRequirementMaxUrl = () => {
  return apiUrl + '/evaluate/englishRequirementMax'
}
export const tuitionFeeMaxUrl = () => {
  return apiUrl + '/evaluate/tuitionFeeMax'
}

export const evaluateSearchUniversityUrl = () => {
  return apiUrl + '/evaluate/searchUniversity'
}
